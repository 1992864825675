import queryString from 'query-string';
import { getPageName } from '../routes';
import { User } from '../types';
import { isServer } from '../util';
import { appConfig } from '../../commonConfig';

// Segment recommends not calling identify() for anon visitors https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
export function identifyUser(user: User) {
  if (appConfig.communicationsDisabled) {
    return;
  }
  // Other traits are filled in by the server
  window.analytics.identify(user.id.toString(), {}, { Intercom: { user_hash: user.intercom_user_hash } });
}

export function getSegmentAnonymousId(): string | undefined {
  if (appConfig.communicationsDisabled) {
    return undefined;
  }

  if (typeof window.analytics.user === 'function' && typeof window.analytics.user().anonymousId === 'function') {
    return window.analytics.user().anonymousId();
  } else {
    return undefined;
  }
}

// Sends event to a Google Tag Manager (GTM) container which is _only_ used for advertising tracking pixels
export function trackGTMEvent(data: {
  // Note: intercomOpened and intercomEmailCollected are used directly in templateService.ts because of how Segment and Intercom are loaded
  event: 'quoteRequested' | 'contactForm' | 'intercomOpened' | 'intercomEmailCollected';
  event_common_attributes?: Record<string, any>;
}) {
  if (!isServer()) {
    window.dataLayer?.push(data);
  }
}

export function segmentTrackEvent(eventName: string, properties?: Record<string, any>) {
  if (appConfig.communicationsDisabled) {
    return;
  }

  if (!isServer()) {
    window.analytics.track(eventName, properties);
  }
}

// Sends page event to Segment, which is needed to load 3rd party snippets as well
export function trackPageViewEvent(data: { hash: string; pathname: string; search: string }) {
  if (appConfig.communicationsDisabled) {
    return;
  }
  const pageName = getPageName(data.pathname);

  // extract utm params
  const queryStrParams = queryString.parse(data.search, { arrayFormat: 'comma' });
  const utmParams = Object.keys(queryStrParams)
    .filter((param) => param.startsWith('utm_'))
    .reduce<Record<string, string>>((acc, param) => {
      const val = queryStrParams[param];

      if (val && typeof val === 'string') {
        acc[param] = val;
      }

      return acc;
    }, {});

  window.analytics.page({
    name: pageName,
    hash: data.hash,
    queryStrParams,
    pageType: 'marketing',
    title: undefined, // Segment automatically sends page title, but it's inaccurate due to how we set page titles (react-helmet)
    ...utmParams,
  });
}
