import * as Sentry from '@sentry/react';
import { isServer, sleep } from '../util';
import { User } from '../types';
import { getFullUserIconUrl } from './userService';
import { trackGTMEvent } from './analyticsService';
import routes, { getPageName } from '../routes';
import { appConfig } from '../../commonConfig';

export function setIntercomSettings(user: User) {
  if (isServer()) {
    return;
  }

  const userId = user.id.toString();
  const fullName = `${user.first_name || ''} ${user.last_name || ''}`.trim();
  const userCreatedAtUnixTimestamp = user.date_joined ? new Date(user.date_joined).getTime() / 1000 : undefined;

  // app_id is initialized in google tag manager on window load with the val in .env, so it will be updated
  // when intercom loads if it doesn't exist in the browser yet
  window.intercomSettings = {
    app_id: window.intercomSettings?.app_id,
    user_id: userId,
    user_hash: user.intercom_user_hash,
    name: fullName,
    avatar: getFullUserIconUrl(user.display_picture),
    email: user.email,
    created_at: userCreatedAtUnixTimestamp,

    // Extra data sent to Intercom. These fields must match the Key names under "people data" in Intercom
    'Creation type': user.creation_type === 'phone' ? 'mobile' : 'web',
    Username: user.username || null,
    'Plan name': user.effective_plan_name || null,
    'Organization name': user.organization_name || null,
    'Organization role': user.org_member_role_name || null,
    'Admin console link': `${appConfig.webAppBaseUrl}/admin-console/user/${userId || ''}/`,
    'Chargebee ID': user.chargebee_customer_id || null,
  };

  // If intercom has not loaded yet it will use window.intercomSettings when loading,
  // if it has loaded, it needs to be updated by using Intercom('update') which will use the data in window.intercomSettings
  if (window.Intercom) {
    window.Intercom('update');
  }
}

export function isIntercomDisabledForPath(pathname: string): boolean {
  const intercomPathBlocklist = [
    routes.VIRTUAL_REMOTE_SNACKNATION_1.path,
    routes.HR_MULTIVIEW_LANDING.path,
    routes.HIGHER_ED_MULTIVIEW_LANDING.path,
    routes.MEETING_MULTIVIEW_LANDING.path,
    routes.COMMUNITY_MULTIVIEW_LANDING.path,
  ];
  return intercomPathBlocklist.includes(pathname);
}

export function updateIntercomSettings(intercomSettings: Record<string, any>): void {
  window.intercomSettings = {
    ...window.intercomSettings,
    ...intercomSettings,
  };

  // If intercom has not loaded yet it will use window.intercomSettings when loading,
  // if it has loaded, it needs to be updated by using Intercom('update') which will use the data in window.intercomSettings
  if (window.Intercom) {
    window.Intercom('update');
  }
}
