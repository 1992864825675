import { PreloadedUserInfo } from '../client/types';

import { appConfig } from '../commonConfig';

export function getGoogleTagManagerTemplate(preloadedUserInfo?: PreloadedUserInfo, nonce?: string) {
  // identity_flags are used in GTM to fire account registered and login events on window load, and used in mixpanel tag init script,
  // and they will be taken care of in the game manager when it initializes google tag manager
  return `
        <!-- DataLayer for GTM. Must be initialized before GTM script below for page load variables to work -->
        <script nonce="${nonce}">
            ${getGoogleTagManagerDataLayer(preloadedUserInfo)}
        </script>

        <!-- Google Tag Manager -->
        <script nonce="${nonce}">
            ${getGoogleTagManagerInitialization()}
        </script>
        <!-- End Google Tag Manager -->
    `;
}

export function getGoogleTagManagerDataLayer(preloadedUserInfo?: PreloadedUserInfo) {
  return `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'page_variables': {
            'page_type': 'marketing',
        },
        'user_variables': {
            'user_id': '${preloadedUserInfo?.id || ''}',
            'is_marketing_tracking_enabled': ${!preloadedUserInfo?.marketingTrackingDisabled},
            'is_authenticated': ${!!preloadedUserInfo?.id},
        },
    });
  `;
}

export function getGoogleTagManagerInitialization() {
  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W86W9K29');
  `;
}

export function injectGoogleTagManagerDynamically() {
  injectGoogleTagManagerScriptDynamically(getGoogleTagManagerDataLayer());
  injectGoogleTagManagerScriptDynamically(getGoogleTagManagerInitialization());
}

function injectGoogleTagManagerScriptDynamically(scriptContent: string) {
  if (!scriptContent) {
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = scriptContent;
  document.head.appendChild(script);
}

function getIntercomEventTracking() {
  // Setup a callback for when Intercom is shown for event tracking
  return `
    // Check if Intercom is loaded and attach event listeners
    if (window.Intercom) {
      window.Intercom('onShow', function (){
        if(typeof window.dataLayer === 'object'){
          window.dataLayer.push({ event: 'intercomOpened' });
        }
      });

      window.Intercom('onUserEmailSupplied', function(){
        if(typeof window.dataLayer === 'object'){
          window.dataLayer.push({ event: 'intercomEmailCollected' });
        }
      });
    } else {
      console.error('Intercom failed to load.');
    }
  `;
}

// NOTE: analytics.page() will be called from the react app, do not call here or there will be duplicates
export const getSegmentSnippet = (segmentWriteKey: string, nonce?: string): string => {
  if (appConfig.communicationsDisabled) {
    return '';
  }

  return `
    <script nonce="${nonce}">
      !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="${segmentWriteKey}";;analytics.SNIPPET_VERSION="5.2.0";
        analytics.load("${segmentWriteKey}");
        analytics.ready(function () {
          ${getIntercomEventTracking()}
        });
      }}();
    </script>
  `;
};
